@import url('https://fonts.googleapis.com/css2?family=Baumans&display=swap');
/* Global Styling */
#root {
  width: calc(100% - 2em);
  height: 100vh;
  color: rgb(241, 241, 241);
}

body {
  background-color: #282c34;
  background-image: url("../public/images/bg-sunscreen.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: rgb(241, 241, 241);
}

a {
  color: rgb(241, 241, 241);
  text-decoration: none;
}

.dark-glass {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 1rem;
}

.inline-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.page {
  text-align: center;
}

.page-header {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.page-title {
  margin-right: auto;
  font-family: 'Baumans', cursive;
}

.page-profile {
  border-radius: 50%;
}

.page-profile:hover {
  cursor: pointer;
}

.page-body {
  padding: 1em;
  justify-content: center;
}

.mb1 {
  margin-bottom: 1em;
}

.mt1 {
  margin-top: 1em;
}
/* End - Global Styling */

/* Home Page */
.home-title {
  font-family: 'Baumans', cursive;
  font-size: calc(20px + 2vmin);
  margin-bottom: 1em;
}

.home-cats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  margin-bottom: 1em;
}

.home-cats-card {
  height: 8em;
  font-size: calc(10px + 1vmin);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-cats-card:active {
  border-color: #000000;
  transform: scale(.96);
}

.home-cats-card-tr {
  align-self: flex-end;
  width: 1em;
}

.home-cats-card-name {
  flex-wrap: wrap;
  align-self: center;
  justify-self: center;
}

.home-cats-card-bl {
  align-self: flex-start;
  width: 1em;
}
/* End - Home Page */

/* List of Drinks */
.dark-glass.drink-list {
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}
.drink-list {
  text-align: start;
}

.drink-list-content {
  height: 100%;
  scrollbar-width: none;
  overflow-y: scroll;
  flex-wrap: wrap;
}
.drink-list-content::-webkit-scrollbar {
  display: none;
}

.drink-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.35em;
}

.drink-item-name {
  margin-right: auto;
}

.drink-icon {
  width: 1em;
  height: 1em;
}
/* End - List of Drinks */

/* MUI styling */
#drink-search-box {
  color: rgb(241, 241, 241);
}

fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(255, 255, 255, 0.125);
  border-radius: 1em;
}

label.MuiInputLabel-root {
  color: rgb(168, 168, 168);
}

label.MuiFormLabel-root {
  color: #1976d2;
}

svg.MuiSvgIcon-root {
  fill: rgb(241, 241, 241);
  border-radius: 50%;
  padding: 0.1em;
}

p.MuiFormHelperText-root {
  color: rgb(241, 241, 241);
}

input.MuiOutlinedInput-input {
  color: rgb(241, 241, 241);
}

button.MuiFab-root {
  background-color: rgba(63, 109, 250, 0.3);
}

.MuiDrawer-root .MuiSvgIcon-root {
  background-color: rgba(63, 109, 250, 0.3);
}

/* End - MUI styling */

/* Order View */
.order-card {
  display: flex;
  flex-direction: column;
  font-size: calc(16px + 1vmin);
  height: 25em;
  border-radius: 10px;
}

.order-card-top {
  width: 100%;
  height: 10%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
}

.order-card-middle {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.order-card-bottom {
  width: 100%;
  height: 10%;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: space-between;
}

.order-icon {
  width: 1.5em;
  height: 1.5em;
}

.favs-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Desktop Media Query */
@media (min-width: 1025px) {
  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  body::-webkit-scrollbar {
    display: none;
  }
}
